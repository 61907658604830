import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Consultant } from 'src/app/models/consultant.model';
import { Order } from 'src/app/models/order.model';
import { ConsultantService } from 'src/app/services/consultant.service';

@Component({
  selector: 'app-pivot-view-table',
  templateUrl: './pivot-view-table.component.html',
  styleUrls: ['./pivot-view-table.component.scss']
})
export class PivotViewTableComponent {
  loading = true;
  reportData: any;
  @Input() set dataSource(value: Order[]) {
    this.reportData = value;
    this._dataSource = new MatTableDataSource<Order>(value);
    this._dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
          case 'projectSum':
          return data.projectSumExVat;
          case 'pipelineProjectSum':
          return data.pipelineProjectSumExVat;
          case 'weightedPipelineProjectSum':
          return data.pipelineProjectSumExVat * (data.pipelinePercentForSuccess/100);
          case 'sales':
          return this.amount(data, "salesRealizators");
          case 'executorAmount':
          return this.amount(data, "executors");
          case 'distribution':
          return this.amount(data, "distribution");
      }
      return data[sortHeaderId];
    };
    this._dataSource.sort = this.sorter;
    console.log(this.sorter);
  }
  @Input() projectType: string;
  @Input() displayedColumns: string[];
  @Input() salesRealizator: string;
  @Input() executor: string;

  consultants: Consultant[];
  _dataSource = new MatTableDataSource<Order>([]);
  @ViewChild(MatSort) sorter: MatSort;

  constructor(
    protected consultantsService: ConsultantService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.consultants = await this.consultantsService.list();
    this.loading = false;
  }

  async ngAfterViewInit(): Promise<void> {
    
  }

  consultant(id: string) {
    return this.consultants.find(_ => _.id == id)?.initials;
  }

  amount(order: Order, key: string) {

    if (key == "salesRealizators" && this.salesRealizator) {
      return order[key]?.filter(_ => _.consultantId == this.salesRealizator).map(_ => _.amount).reduce((prev, curr) => prev + curr, 0);
    } else if (key == "executors" && this.executor) {
      return order[key]?.filter(_ => _.consultantId == this.executor).map(_ => _.amount).reduce((prev, curr) => prev + curr, 0);
    } else if (key == "distribution") {
      if (this.executor) {

        return order.invoices?.filter(_ => _.distribution?.some(d => d.consultantId == this.executor)).map(
          i => i.distribution.filter(d => d.consultantId == this.executor)?.map(t => t.amount)
            .reduce((prev, curr) => prev + curr, 0)
        ).reduce((prev, curr) => prev + curr, 0);
      } else {
        return order.invoices?.map(
          i => i.distribution?.map(t => t.amount)
            .reduce((prev, curr) => prev + curr, 0)
        ).reduce((prev, curr) => prev + curr, 0);
      }
    }
    return order[key]?.map(_ => _.amount).reduce((prev, curr) => prev + curr, 0);
  }
}
