import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ConsultantService } from '../services/consultant.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConsultantSummary } from '../models/consultant-summary.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Consultant } from '../models/consultant.model';
import { Department } from '../models/department.model';
import { DepartmentService } from '../services/department.service';

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-consultant-summary',
  templateUrl: './consultant-summary.component.html',
  styleUrls: ['./consultant-summary.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConsultantSummaryComponent implements OnInit {
  // adapter: DateAdapter<any>;
  loading = true;
  date = new FormControl<Date>(new Date());
  dataSource: MatTableDataSource<ConsultantSummary>;
  totals: ConsultantSummary;

  displayedColumns = ["consultant", "initials", "personalRevenue", "invoicedPersonalRevenue", "weightedPipeline", "leads", "realizedSales"];
  expandedElement: ConsultantSummary | null;
  detailsType: "personalRevenue" | "invoicedPersonalRevenue" | "weightedPipeline" | "leads" | "realizedSales";
  departmentControl = new FormControl();
  departments: Department[];

  constructor(
    private consultants: ConsultantService,
    public departmentsService: DepartmentService,

  ) {
  }

  async ngOnInit(): Promise<void> {
    this.departments = await this.departmentsService.list();
    await this.loadSummary(this.date.value.getFullYear());

    this.departmentControl.valueChanges.subscribe(async () => {
      await this.loadSummary(this.date.value.getFullYear(), this.departmentControl.value);
    });
  }

  async setYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Date>) {
    let ctrlValue: Date = this.date.value || new Date();
    ctrlValue.setFullYear(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();

    await this.loadSummary(this.date.value.getFullYear());
  }

  async loadSummary(year: number, departmentId?: string) {
    let summary = await this.consultants.summary(year);

    if (departmentId) {
      summary = summary.filter(_ => _.consultant.departmentId == departmentId);
    }

    this.totals = new ConsultantSummary(new Consultant("Total", "", "", ""),
      summary.map(_ => _.personalRevenue)?.reduce((prev, curr) => prev + curr, 0),
      summary.map(_ => _.invoicedPersonalRevenue)?.reduce((prev, curr) => prev + curr, 0),
      summary.map(_ => _.weightedPipeline)?.reduce((prev, curr) => prev + curr, 0),
      summary.map(_ => _.leads)?.reduce((prev, curr) => prev + curr, 0),
      summary.map(_ => _.realizedSales)?.reduce((prev, curr) => prev + curr, 0),
    );

    this.dataSource = new MatTableDataSource<ConsultantSummary>(summary);
    this.loading = false;
  }

  showDetails(row, type) {
    if (this.expandedElement == row && this.detailsType == type) {
      this.expandedElement = null;
    } else {
      if (this.expandedElement != row) {
        this.expandedElement = row;
      }

      if (this.detailsType != type) {
        this.detailsType = type;
      }
    }
  }

  async exportPdf() {
    let file = await this.consultants.summaryPdf(this.date.value.getFullYear());
    const blob = new Blob([file.body], { type: `application/pdf;` });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `totaler_${this.date.value.getFullYear()}.pdf`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async exportExcel() {
    let file = await this.consultants.summaryExcel(this.date.value.getFullYear());
    const blob = new Blob([file.body], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;` });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `totaler_${this.date.value.getFullYear()}.xlsx`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
