import { BaseModel } from "./base.model";
import { Consultant, DistributionAmount, ExecutorAmount, SalesRealizatorAmount } from "./consultant.model";
import { Department } from "./department.model";
import { FeeBasisAdjustment } from "./fee-basis-adjustment.model";

export class Order extends BaseModel {
  constructor(
    public taskNumber?: string,
    public year?: Date,
    public consultantId?: string,
    public consultant?: Consultant,
    public customerAndTask?: string,
    public projectSumExVat?: number,
    public pipelineProjectSumExVat?: number,
    public pipelinePercentForSuccess?: number,
    public pipelineWeightedProjectSum?: number,
    public leadCreatorId?: string,
    public leadCreator?: Consultant,
    public salesRealizators?: SalesRealizatorAmount[],
    public executors?: ExecutorAmount[],
    public expectedInvoice?: ExpectedInvoice[],
    public comment?: string,
    public departmentId?: string,
    public department?: Department,
    public economicCustomerNumber?: string,
    public invoices?: Invoice[],
    public feeBasisAdjustments?: FeeBasisAdjustment[]
    ) {
    super();
  }
}

export class ExpectedInvoice extends BaseModel {
  constructor(
    public month?: Date,
    public amount?: number
  ) {
    super();

  }
}

export enum InvoiceStatus {
  Open,
  SentToEconomic,
  Manual
}

export class Invoice extends BaseModel {
  constructor(
    public orderId?: string,
    public status?: InvoiceStatus,
    public date?: Date,
    public projectLeadId?: string,
    public departmentId?: string,
    public taskNumber?: string,
    public economicCustomerNumber?: string,
    public internalText?: string,

    public customerName?: string,
    public customerDepartment?: string,
    public customerAdress?: string,
    public customerPostCodeAndCity?: string,

    public customerContactPerson?: string,
    public customerContactPersonEmail?: string,
    public customerOrderId?: string,
    public customerRefNumber?: string,
    public customerText?: string,

    public customerEan?: string,
    public customerCvr?: string,

    public tasks?: InvoiceTask[],
    public transport?: InvoiceTask[],
    public expenses?: InvoiceExpense[],
    public distribution?: DistributionAmount[],
    public salesRealizatorDistribution?: DistributionAmount[],
    public order?: Order,

    public economicInvoiceNumber?: string,
  ) {
    super();
  }
}

export class InvoiceTask {
  constructor(
    public description?: string,
    public amount?: number,
    public price?: number,
    public productNumber?: string,
    ) {

  }
}

export class InvoiceExpense {
  constructor(
    public type?: string,
    public description?: string,
    public price?: number,
    ) {

  }
}