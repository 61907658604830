<div class="sticky-container" style="display: flex;flex-direction: column;" [style.height]="containerHeight">

  <div class="sticky-content">
    <div class="button-container">
      <button mat-raised-button color="primary" style="width: fit-content;" (click)="createOrder()">
        <mat-icon>add</mat-icon>
        Opret ny opgave
      </button>

      <button mat-raised-button color="primary" style="width: fit-content;" (click)="exportAllToExcel()">
        <mat-icon>download</mat-icon>
        Exporter alt til Excel
      </button>
    </div>

    <div class="filter-container">
      <app-table-column-picker (displayedColumnsChange)="displayedColumnsChanger($event)"></app-table-column-picker>
      @if (consultants && departments) {
      <app-order-filter [consultants]="consultants" [departments]="departments"
        (changed)="filter($event)"></app-order-filter>
      }
    </div>
  </div>

  <div class="table-container">
    <mat-table class="mat-elevation-z2" matSort [dataSource]="dataSource" cdkDropList
      cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropListDropped($event)">

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef cdkDrag [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent"
          cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Handlinger</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          @if (auth.isAdmin()) {
          <button mat-icon-button (click)="openOrder(element)" matTooltip="Rediger">
            <mat-icon>edit</mat-icon>
          </button>
          }
          <button mat-icon-button [style.visibility]="element.projectSumExVat ? 'visible' : 'hidden'"
            (click)="createInvoice(element)" matTooltip="Opret faktura">
            <mat-icon [style.color]="invoiceStatus(element)">description</mat-icon>
          </button>
          @if (auth.isAdmin()) {
          <button mat-icon-button (click)="deleteOrder(element)" matTooltip="Slet Projekter">
            <mat-icon>delete</mat-icon>
          </button>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Opgave nr." cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Opgave ID</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.taskNumber}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="year">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="År" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>År</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.year | date:"yyyy"}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="consultantId">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Konsulent/projektejer"
          cdkDrag [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>konsulent/ projektejer</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{consultant(element.consultantId)}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customerAndTask">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Kunde og opgave" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Kunde og opgave</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.customerAndTask}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="customerNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Kunde og opgave" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Kunde nr.</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.customerAndTask}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="projectSumExVat">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Projektsum Ex. Moms" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Projektsum Ex. Moms</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="right-align">
          @if (element.projectSumExVat) {
          <span>{{element.projectSumExVat | number: '1.0-0'}}</span>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pipelineProjectSumExVat">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label"
          matTooltip="Pipeline projektsum ex. moms" cdkDrag [cdkDragData]="displayedColumns"
          cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Pipeline projektsum ex. moms</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="right-align">
          @if (element.pipelineProjectSumExVat) {
          <span>{{element.pipelineProjectSumExVat | number:
            '1.0-0'}}</span>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pipelinePercentForSuccess">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Pipeline % for Succes"
          cdkDrag [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Pipeline % for win</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="right-align">
          @if (element.pipelinePercentForSuccess) {
          <span>{{element.pipelinePercentForSuccess |
            number:'1.0-0'}}%</span>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pipelineWeightedProjectSum">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Pipeline Vægtet Projektsum"
          cdkDrag [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Pipeline Vægtet Projektsum</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="right-align">
          @if (element.pipelinePercentForSuccess) {
          <span>{{(element.pipelinePercentForSuccess/100) *
            element.pipelineProjectSumExVat | number:
            '1.0-0'}}</span>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="leadCreatorId">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Lead Creator" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Lead Creator</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{consultant(element.leadCreatorId)}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="salesRealizators">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Salgsrealisator" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Salgsrealisator</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="display: flex;flex-direction: column;padding: 10px;">
          @for (c of element.salesRealizators; track c) {
          <div style="display: flex;justify-content: space-around;width: 175px;">
            <span class="percentage">{{ (c.amount / element.projectSumExVat) * 100 | number:'1.0-0' }}%
            </span>
            <span class="consultant-initials"> {{
              consultant(c.consultantId) }}</span>
            <span class="consultant-amount">
              {{ c.amount | number: '1.0-0' }}</span>
          </div>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="executors">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Salgsrealisator" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Udførende</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="display: flex;flex-direction: column;padding: 10px;">
          @for (c of element.executors; track c) {
          <div style="display: flex;justify-content: space-around;width: 175px;">
            <span class="percentage">{{ (c.amount / element.projectSumExVat) * 100 | number:'1.0-0' }}%
            </span>
            <span class="consultant-initials"> {{
              consultant(c.consultantId) }}</span>
            <span class="consultant-amount">
              {{ c.amount | number: '1.0-0' }}</span>
          </div>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expectedInvoice">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="column-label" matTooltip="Forventet faktura måned"
          cdkDrag [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Forventet fakturering</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="display: flex;flex-direction: column;padding: 10px;">
          @for (i of element.expectedInvoice; track i) {
          <div style="display: flex;justify-content: space-around;width: 175px;">
            <span class="percentage">{{i.month | date: 'MMM yyyy'}}
            </span>
            <span class="consultant-amount">{{ i.amount | number: '1.0-0' }}</span>
          </div>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="distribution">
        <mat-header-cell *matHeaderCellDef class="column-label" matTooltip="Faktureret" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x" style="flex:150px">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Realiseret fakturering</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="display: flex;flex-direction: column;padding: 10px;">
          @for (i of distribution[element.id]; track i) {
          <div style="display: flex;justify-content: space-around;width: 80px;">
            <span class="percentage">{{consultant(i.consultantId)}}
            </span>
            <span class="consultant-amount">{{ i.amount | number: '1.0-0' }}</span>
          </div>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef class="column-label" matTooltip="Kommentar" cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>Kommentar</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          @if (element.comment) {
          <span [matTooltip]="element.comment"> {{element.comment | truncate:10:"…"}} </span>
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="departmentId">
        <mat-header-cell *matHeaderCellDef>
          Forretningsområde
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{department(element.departmentId)}} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hubspotDeal">
        <mat-header-cell *matHeaderCellDef class="column-label" matTooltip="Economic kunde nr." cdkDrag
          [cdkDragData]="displayedColumns" cdkDragPreviewContainer="parent" cdkDragLockAxis="x">
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
          <div><span>HubSpot</span></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="text-align: center;">
          @if (element.hubSpotId) {
          <a target="_blank" [href]="'https://app-eu1.hubspot.com/contacts/25986122/deal/' + element.hubSpotId">Åben</a>
          }
        </mat-cell>
      </ng-container>

      <mat-header-row class="header-row" *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>