import { BaseModel } from "./base.model";

export class Consultant extends BaseModel {

    constructor(
        public name?: string,
        public initials?: string,
        public departmentId?: string,
        public email?: string
    ) {
        super();

    }
}

export class SalesRealizatorAmount extends BaseModel {
    constructor(
        public consultantId?: string,
        public orderId?: string,
        public amount?: number
    ) {
        super();
    }
}

export class ExecutorAmount extends BaseModel {
    constructor(
        public consultantId?: string,
        public orderId?: string,
        public amount?: number,
    ) {
        super();
    }
}

export class DistributionAmount extends BaseModel {
    constructor(
        public consultantId?: string,
        public orderId?: string,
        public amount?: number,
        public invoiceId?: string,
        public expenses?: number
    ) {
        super();
    }
}