@if (consultantId) {
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
    <ng-container matColumnDef="taskNumber">
      <th mat-header-cell *matHeaderCellDef> Ordre ID </th>
      <td mat-cell *matCellDef="let element"> {{element?.order.taskNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="customerAndTask">
      <th mat-header-cell *matHeaderCellDef> Kunde/opgave </th>
      <td mat-cell *matCellDef="let element"> {{element?.order.customerAndTask}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"> {{element?.type}} </td>
    </ng-container> -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Beløb </th>
      <td mat-cell *matCellDef="let element"> {{element?.amount | number: '1.0-0'}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
}