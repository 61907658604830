import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Order } from '../models/order.model';
import { InvoiceService } from '../services/invoice.service';

@Pipe({
  name: 'total'
})
export class TotalPipe implements PipeTransform {
  constructor(
    private invoiceService: InvoiceService,
  ) {

  }

  transform(value: Order[], key: 'pipelineProjectSumExVat' | 'salesRealizators' | 'executors' | 'pipelineWeightedProjectSum' | 'projectSumExVat' | 'distribution', consultant?: string): number {

    if (key == "salesRealizators" || key == "executors") {
      if (consultant) {
        return value
          .map(item => item[key].filter(_ => _.consultantId == consultant).map(c => c.amount).reduce((acc, value) => acc + value, 0))
          .reduce((acc, value) => acc + value, 0);
      }
      return value
        .map(item => item[key].map(c => c.amount).reduce((acc, value) => acc + value, 0))
        .reduce((acc, value) => acc + value, 0);
    } else if (key == "pipelineWeightedProjectSum") {
      return value
        .map(item => item["pipelineProjectSumExVat"] * (item.pipelinePercentForSuccess / 100))
        .reduce((acc, value) => acc + value, 0);
    } else if (key == "distribution") {
      if (consultant) {
        return value.map(order => order.invoices?.map(
          i => i.distribution?.filter(_ => _.consultantId == consultant).map(t => t.amount)
            .reduce((prev, curr) => prev + curr, 0)
        ).reduce((prev, curr) => prev + curr, 0))
          .reduce((prev, curr) => prev + curr, 0);
      }
      return value.map(order => order.invoices?.map(
        i => i.distribution?.map(t => t.amount)
          .reduce((prev, curr) => prev + curr, 0)
      ).reduce((prev, curr) => prev + curr, 0))
        .reduce((prev, curr) => prev + curr, 0);
    }

    return value
      .map(item => item[key])
      .reduce((acc, value) => acc + value, 0);
  }

}
