@if (!loading) {
<div class="pivot-dropdown-flex-container">
  <app-pivot-view-drowdown title="År" identifier="year" [options]="yearOptions" [value]="yearValue"
    (valueChange)="handleValueChange($event)" />
  <app-pivot-view-drowdown title="Projekt-type" identifier="projectType" [options]="projectTypeOptions"
    [value]="projectTypeValue" (valueChange)="handleValueChange($event)" />
  <app-pivot-view-drowdown title="Forretningsområde" identifier="department" [options]="departmentOptions"
    [value]="departmentValue" (valueChange)="handleValueChange($event)" />
  <app-pivot-view-drowdown title="Lead creator" identifier="leadCreator" [options]="leadCreatorOptions"
    [value]="leadCreatorValue" (valueChange)="handleValueChange($event)" />
  <app-pivot-view-drowdown title="Salgsrealisator" identifier="salesRealizer" [options]="salesRealizerOptions"
    [value]="salesRealizerValue" (valueChange)="handleValueChange($event)" />
  <app-pivot-view-drowdown title="Udførende konsulent" identifier="executor" [options]="executorOptions"
    [value]="executorValue" (valueChange)="handleValueChange($event)" />
</div>
}
<div class="pivot-table-container">
  <app-pivot-view-table [dataSource]="dataSource" [displayedColumns]="displayedTableColumns"
    [salesRealizator]="salesRealizerValue" [executor]="executorValue"></app-pivot-view-table>
</div>