import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Moment } from 'moment';
import { Observable, map, startWith } from 'rxjs';
import { DDMMYYYY_FORMAT } from 'src/app/invoices/invoice/invoice.component';
import { Consultant, DistributionAmount } from 'src/app/models/consultant.model';
import { Invoice, InvoiceExpense, InvoiceStatus, InvoiceTask, Order } from 'src/app/models/order.model';
import { Settings } from 'src/app/models/settings.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderService } from 'src/app/services/order.service';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

export declare type OrderId = {
  taskNumber: string;
  orderId: string;
};

@Component({
  selector: 'app-manual-invoicing',
  templateUrl: './manual-invoicing.component.html',
  styleUrls: ['./manual-invoicing.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_FORMAT },
  ]
})
export class ManualInvoicingComponent implements OnInit {
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  loading = true;
  settings: Settings;

  order: Order;
  invoice: Invoice = new Invoice();

  formGroup: FormGroup;
  orderIds: OrderId[];
  orderControl = new FormControl();
  filteredOrders: Observable<OrderId[]>;
  dateControl = new FormControl();
  consultants: Consultant[];
  leadCreatorControl = new FormControl();
  filteredLeadCreators: Observable<Consultant[]>;
  salesRealizatorControl = new FormControl();
  executorControl = new FormControl();

  invoiceAmount: number;

  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    private orderService: OrderService,
    protected consultantService: ConsultantService,
    private invoiceService: InvoiceService
  ) {
    this.formGroup = new FormGroup({
      order: this.orderControl,
      date: this.dateControl,
      leadCreator: this.leadCreatorControl,
      salesRealizator: this.salesRealizatorControl,
      executor: this.executorControl
    });
  }

  async ngOnInit(): Promise<void> {
    this.settings = await this.settingsService.get() ?? new Settings(null, null);
    this.orderIds = await this.orderService.taskNumbers();
    this.consultants = await this.consultantService.list();

    this.filteredOrders = this.orderControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const taskNumber = typeof value === 'string' ? value : value?.taskNumber;
        return taskNumber ? this.filterOrders(taskNumber as string) : this.orderIds.slice();
      }),
    );

    this.orderControl.valueChanges.subscribe(async _ => {
      if (_ && typeof _ !== "string") {
        this.order = await this.orderService.get(_.orderId);
        const consultant = this.consultants.find(_ => _.id == this.order.consultantId);
        const leadCreator = this.consultants.find(_ => _.id == this.order.leadCreatorId);

        this.invoice.orderId = this.order.id;
        this.invoice.status = InvoiceStatus.Manual;
        this.invoice.projectLeadId = leadCreator?.id;
        this.invoice.departmentId = consultant?.departmentId;
        this.invoice.taskNumber = this.order.taskNumber;
        this.invoice.customerName = this.order.customerAndTask;
        this.invoice.economicCustomerNumber = this.order.economicCustomerNumber;

        this.leadCreatorControl.setValue(leadCreator);

        this.invoice.distribution = [];
        this.invoice.salesRealizatorDistribution = [];
      }
    });

    this.filteredLeadCreators = this.leadCreatorControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const initials = typeof value === 'string' ? value : value?.initials;
        return initials ? this.filterLeadCreators(initials as string) : this.consultants.slice();
      }),
    );

    this.leadCreatorControl.valueChanges.subscribe(_ => {
      if (_ && typeof _ !== "string") {
        this.invoice.projectLeadId = _.id;
      }
    });

    this.salesRealizatorControl.valueChanges.subscribe(salesRealizators => {
      let toRemove = this.invoice.salesRealizatorDistribution
        ?.map(_ => _.consultantId)
        ?.filter(_ => !salesRealizators?.includes(_));

      toRemove?.forEach(e => {
        let idx = this.invoice.salesRealizatorDistribution.findIndex(__ => __.consultantId == e);
        this.invoice.salesRealizatorDistribution.splice(idx, 1);
      });

      salesRealizators?.forEach(e => {
        if (!this.invoice.salesRealizatorDistribution?.some(__ => __.consultantId == e)) {
          let s = this.order.salesRealizators.find(_ => _.consultantId == e);
          this.invoice.salesRealizatorDistribution.push(new DistributionAmount(e, this.order.id, (s?.amount / this.order.projectSumExVat) * this.invoiceAmount));
        }
      });
    });

    this.executorControl.valueChanges.subscribe(executors => {
      let toRemove = this.invoice.distribution
        ?.map(_ => _.consultantId)
        ?.filter(_ => !executors?.includes(_));

      toRemove?.forEach(e => {
        let idx = this.invoice.distribution.findIndex(__ => __.consultantId == e);
        this.invoice.distribution.splice(idx, 1);
      });

      executors?.forEach(e => {
        if (!this.invoice.distribution?.some(__ => __.consultantId == e)) {
          let s = this.order.executors.find(_ => _.consultantId == e);
          this.invoice.distribution.push(new DistributionAmount(e, this.order.id, (s?.amount / this.order.projectSumExVat) * this.invoiceAmount));
        }
      });
    });

    this.loading = false;
  }

  private filterOrders(value: string): OrderId[] {
    const filterValue = value.toLowerCase();
    return this.orderIds.filter(option => option.taskNumber.toLowerCase().includes(filterValue));
  }

  orderDisplayFn(order: OrderId): string {
    return order && order.taskNumber ? order.taskNumber : '';
  }

  private filterLeadCreators(value: string): Consultant[] {
    const filterValue = value.toLowerCase();
    return this.consultants.filter(option => option.initials.toLowerCase().includes(filterValue));
  }

  dateSelected(event: MatDatepickerInputEvent<Moment>, datepicker: MatDatepicker<Date>) {
    if (datepicker) {
      datepicker.close();
    }

    this.invoice.date = event.value.add(12, "hours").toDate();
  }

  consultant(id: string) {
    return this.consultants?.find(_ => _.id == id);
  }

  async save() {
    if (!this.valid()) {
      this.formGroup.markAllAsTouched();
      this.ngModels.forEach(_ => _.control.markAllAsTouched());
    } else {
      try {
        this.invoice.tasks = [new InvoiceTask("Manuelt oprettet", 1, this.invoiceAmount - this.totalExpenses())];
        this.invoice.distribution.forEach(_ => {
          if (_.expenses) {
            if (!this.invoice.expenses)
              this.invoice.expenses = [];

            this.invoice.expenses.push(new InvoiceExpense(
              "Manuel indtastet faktura",
              this.consultants.find(c => c.id == _.consultantId)?.initials, _.expenses));
          }
        });
        await this.invoiceService.save(this.invoice);
        this.snackBar.open("Fakturaen er gemt");
        this.reset();
      } catch (error: any) {
        this.snackBar.open(error.message);
      }
    }
  }

  reset() {
    this.ngModels.forEach(_ => _.control.reset());
    this.formGroup.reset();
    this.invoice = new Invoice();
    this.order = null;
  }

  valid() {
    return !this.salesRealizatorAmountError() && !this.executorAmountError()
      && this.formGroup.valid && this.ngModels.map(_ => _.valid).every(_ => _);
  }

  salesRealizatorAmountError() {
    let total = this.invoice.salesRealizatorDistribution?.map(_ => +_.amount).reduce((prev, curr) => prev + curr, 0);
    let totalInvoice = this.invoiceAmount - this.totalExpenses();
    return !(total >= totalInvoice - 0.01 && total <= totalInvoice + 0.01);
  }

  executorAmountError() {
    let total = this.invoice.distribution?.map(_ => (+_.expenses || 0) + _.amount).reduce((prev, curr) => prev + curr, 0);
    return !(total >= this.invoiceAmount - 0.01 && total <= this.invoiceAmount + 0.01);
  }

  totalExpenses() {
    return this.invoice.distribution?.map(_ => +_.expenses || 0).reduce((prev, curr) => prev + curr, 0);
  }

  updateAmount(da: DistributionAmount, percentage: number) {
    da.amount = (percentage / 100) * (this.invoiceAmount - this.totalExpenses());
  }

  invoiceAmountSet($event: any) {
    this.invoiceAmount = $event;
    this.salesRealizatorControl.setValue(this.order.salesRealizators.map(_ => _.consultantId));
    this.executorControl.setValue(this.order.executors.map(_ => _.consultantId));
  }

  invoiceExpensesSet($event: any, da: DistributionAmount) {
    da.expenses = $event;

    // TODO update salesreal and executor amount
    // const totalExpenses = this.totalExpenses();
    // setTimeout(() => {
    //   this.invoice.salesRealizatorDistribution.forEach(_ => {
    //     _.amount = (_.amount / (this.invoiceAmount - this.totalExpenses())) * (this.invoiceAmount - this.totalExpenses());
    //     console.log("srd", _.amount);
    //   });
    // });
  }
}
