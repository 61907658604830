<div style="display: flex;flex-direction: row;">
  <h2 mat-dialog-title style="display: inline;flex: auto;">Faktura</h2>
  <button mat-button (click)="close()">X</button>
</div>
<mat-dialog-content>
  @if (!loading) {
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Opgave id</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.taskNumber" readonly>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Economic-kunde nr. (hvis ukendt anfør 999)</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.economicCustomerNumber" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Dato for indlevering til fakturering (DD/MM/ÅÅÅÅ)</mat-label>
      <input matInput [matDatepicker]="dp" [(ngModel)]="invoice.date" (dateChange)="dateSelected('date',$event, dp)"
        required>
      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp>
      </mat-datepicker>
    </mat-form-field>
    <app-autocomplete-chips [formControl]="projectLeadControl" label="Projektejer"
      [displayFn]="consultantsService.displayFn" [valueFn]="consultantsService.valueFn" [options]="consultants"
      [multiple]="false" [required]="true"></app-autocomplete-chips>
    <mat-form-field appearance="outline">
      <mat-label>Intern tekst til bogholderiet</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.internalText">
    </mat-form-field>
    <h3>Oplysninger om kunden</h3>
    <mat-form-field appearance="outline">
      <mat-label>Navn</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerName" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Vejnavn</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerAdress" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Post nummer og by</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerPostCodeAndCity" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Forretningsområde</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerDepartment">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Kontaktperson hos kunden</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerContactPerson" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Emailadresse på kontaktperson</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerContactPersonEmail" required email>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>P.O. nr./Indkøbsordrenr.</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerOrderId">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Anden ref. som Kunden vil have på faktura</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerRefNumber">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>EAN</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerEan">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>CVR</mat-label>
      <input matInput type="text" [(ngModel)]="invoice.customerCvr">
    </mat-form-field>
    <h3>Tekst og beløb der skal fremgå af faktura</h3>
    @if (invoice.tasks) {
    @for (t of invoice.tasks; track $index) {
    <div style="display: flex;flex-direction:row;">
      <div style="display: flex;flex-direction:column;">
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>Economic produkt</mat-label>
            <mat-select [name]="'task-'+$index" [(ngModel)]="t.productNumber" required>
              @for (p of productsDropdown; track p) {
              <mat-option [value]="p.value">{{p.display}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Beskrivelse</mat-label>
            <input matInput type="text" [(ngModel)]="t.description" required>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>Antal timer/dagestk</mat-label>
            <input matInput type="text" [(ngModel)]="t.amount" (ngModelChange)="updateDistribution()" required>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Kr</mat-label>
            <input matInput type="text" [(ngModel)]="t.price" (ngModelChange)="updateDistribution()" required>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Beløb</mat-label>
            <input matInput type="text" [ngModel]="t.amount * t.price | number:'1.0-2'" readonly>
          </mat-form-field>
        </div>
      </div>
      <button mat-icon-button (click)="deleteLine($index, invoice.tasks)" matTooltip="Slet" style="margin-top: 70px;">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }
    }
    @if (totalTasks() > order?.projectSumExVat) {
    <h3 style="color:red">Beløbene overstige projektsummen</h3>
    }
    <button mat-raised-button color="primary" style="width: fit-content;margin-bottom: 1rem;" (click)="addTask()">Tilføj
      linje</button>

    <h3>Fordeling</h3>
    @for (executor of invoice.distribution; track executor) {
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Konsulent</mat-label>
        <input matInput type="text" [value]="consultant(executor.consultantId)" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Beløb kr.</mat-label>
        <input matInput type="text" [ngModel]="executor.amount | number: '1.0-2' : 'da'"
          (blur)="amountChanged($event.srcElement, executor)" required enterNavigation>
      </mat-form-field>
    </div>
    }
    @if (totalDistribution() > totalTasks()) {
    <h3 style="color:red">Fordelingen overstige det fakturerede</h3>
    }
    <h3>Transport i bil til fakturering</h3>
    @if (invoice.transport) {
    @for (t of invoice.transport; track t; let i = $index) {
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Fra og til</mat-label>
        <input matInput type="text" [(ngModel)]="t.description" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Takst</mat-label>
        <input matInput type="text" [ngModel]="t.price | number: '1.0-2'" (ngModelChange)="t.price=parseNumber($event)"
          required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Antal km</mat-label>
        <input matInput type="text" [(ngModel)]="t.amount" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Beløb</mat-label>
        <input matInput type="text" [ngModel]="t.amount * t.price | number:'1.0-2'" readonly>
      </mat-form-field>
      <button mat-icon-button (click)="deleteLine(i, invoice.transport)" matTooltip="Slet">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }
    }
    <button mat-raised-button color="primary" style="width: fit-content;margin-bottom: 1rem;"
      (click)="addTransport()">Tilføj linje</button>
    <h3>Andre udgifter til fakturering</h3>
    @if (invoice.expenses) {
    @for (t of invoice.expenses; track t; let i = $index) {
    <div class="form-row">
      <mat-form-field appearance="outline">
        <mat-label>Logi og forplejning + diverse</mat-label>
        <input matInput type="text" [(ngModel)]="t.type" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Beskrivelse (dato, sted)</mat-label>
        <input matInput type="text" [(ngModel)]="t.description" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Kr</mat-label>
        <input matInput type="text" [(ngModel)]="t.price" required>
      </mat-form-field>
      <button mat-icon-button (click)="deleteLine(i, invoice.expenses)" matTooltip="Slet">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }
    }
    <button mat-raised-button color="primary" style="width: fit-content;margin-bottom: 1rem;"
      (click)="addExpense()">Tilføj linje</button>
    <h3>I alt til fakturering uden moms: {{total() | number:'1.0-2'}}</h3>
  </div>
  }

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close>Annuller</button>
  @if (invoice.status != 1) {
  <button mat-raised-button color="primary" (click)="create()">{{ invoice.id ? 'Gem' :
    'Opret faktura'
    }}</button>
  }
  @if (invoice.id && invoice.status == 0) {
  <button mat-button color="primary" (click)="sendToEconomic()">
    <mat-icon>upload</mat-icon>
    Send til economic
  </button>
  }
</mat-dialog-actions>