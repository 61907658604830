import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, map, merge } from 'rxjs';
import { Consultant } from 'src/app/models/consultant.model';
import { Department } from 'src/app/models/department.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConsultantService } from 'src/app/services/consultant.service';
import { DepartmentService } from 'src/app/services/department.service';

export declare type OrderFilter = { type: string, owner: string[], department: string[], text: string };

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss']
})
export class OrderFilterComponent implements OnInit, AfterViewInit {

  @Input() consultants: Consultant[] = [];
  @Input() departments: Department[] = [];

  @Output() changed = new EventEmitter<OrderFilter>();

  projectTypesDropdown = [
    { display: "Alle", value: "ALL" },
    { display: "Projekter", value: "ORDERS" },
    { display: "Pipeline", value: "PIPELINE" },
  ];

  projectOwnerDropdown = [];
  departmentDropdown = [];
  projectTypesControl = new FormControl("ALL");
  projectOwnerControl = new FormControl();
  departmentControl = new FormControl();
  textControl = new FormControl();

  lastEmitted: OrderFilter;

  constructor(
    protected auth: AuthService,
    protected consultantsService: ConsultantService,
    protected departmentsService: DepartmentService
  ) {

  }

  ngOnInit(): void {
    this.projectOwnerDropdown = this.consultants.map(_ => ({ display: _.initials, value: _.id }));
    this.departmentDropdown = this.departments.map(_ => ({ display: _.name, value: _.id }));
    setTimeout(() => {
      this.projectOwnerControl.setValue([this.consultants.find(_ => _.email == this.auth.activeAccount.username)?.id]);
    });
    merge(
      this.projectTypesControl.valueChanges,
      this.projectOwnerControl.valueChanges,
      this.departmentControl.valueChanges,
      this.textControl.valueChanges
    ).pipe(distinctUntilChanged(), debounceTime(500),
      map(_ => {
        return {
          type: this.projectTypesControl.value,
          owner: this.projectOwnerControl.value,
          department: this.departmentControl.value,
          text: this.textControl.value
        };
      })
    ).subscribe(_ => {
      this.lastEmitted = _;
      this.changed.emit(_);
    });
  }

  ngAfterViewInit(): void {
  }

  reset() {
    this.projectTypesControl.setValue("ALL");
    this.projectOwnerControl.setValue(null);
    this.departmentControl.setValue(null);
    this.textControl.setValue(null);
  }

  emitLast(force = false) {
    this.changed.emit(this.lastEmitted);
  }
}

