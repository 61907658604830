import { LOCALE_ID, NgModule, enableProdMode, isDevMode } from '@angular/core';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnterNavigation, MonthYearFormat, NoDuplicateTaskNumberValidatorDirective, OrderComponent, YearFormat } from './orders/order/order.component';
import { OrdersComponent } from './orders/orders.component';
import { MaterialModule } from './ui/material.module';
import { AdminComponent } from './admin/admin.component';
import { SelectAutocompleteComponent } from './ui/select-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ConsultantsComponent } from './admin/consultants/consultants.component';
import { ConsultantComponent } from './admin/consultants/consultant/consultant.component';
import { TableColumnPickerComponent } from './table-column-picker/table-column-picker.component';
import { OrderFilterComponent } from './orders/order-filter/order-filter.component';
import { DepartmentsComponent } from './admin/departments/departments.component';
import { DepartmentComponent } from './admin/departments/department/department.component';
import { PivotViewComponent } from './pivot-view/pivot-view.component';
import { PivotViewDrowdownComponent } from './pivot-view/pivot-view-drowdown/pivot-view-drowdown.component';
import { PivotViewTableComponent } from './pivot-view/pivot-view-table/pivot-view-table.component';
import { TotalPipe } from './pipes/total.pipe';
import { ConsultantSummaryComponent } from './consultant-summary/consultant-summary.component';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { LoginComponent } from './login/login.component';
import { msalConfig, protectedResources, loginRequest } from './auth-config';
import { GroupGuard } from './group.guard';
import { InvoiceComponent } from './invoices/invoice/invoice.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SettingsComponent } from './admin/settings/settings.component';
import { AuditComponent } from './admin/audit/audit.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { ConsultantSummaryDetailsComponent } from './consultant-summary/consultant-summary-details/consultant-summary-details.component';
import { CurrencyFormatPipe, CurrencyFormatterDirective } from './pipes/currency.pipe';
import { DayMonthYearFormat, FeeBasisComponent } from './fee-basis/fee-basis.component';
import { ManualInvoicingComponent } from './admin/manual-invoicing/manual-invoicing.component';
import { AutocompleteChipsComponent } from './ui/autocomplete-chips/autocomplete-chips.component';
import { FeeBasisAdjustmentComponent } from './admin/fee-basis-adjustment/fee-basis-adjustment.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

if (environment.isStaging) {
  enableProdMode();
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
* MSAL Angular will automatically retrieve tokens for resources
* added to protectedResourceMap. For more info, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiApp.endpoint, protectedResources.apiApp.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

registerLocaleData(localeDa);

@NgModule({
  declarations: [
    AppComponent,

    InvoiceComponent,
    OrderComponent,
    OrdersComponent,

    AdminComponent,
    ManualInvoicingComponent,
    ConsultantsComponent,
    FeeBasisAdjustmentComponent,
    FeeBasisComponent,


    SelectAutocompleteComponent,
    AutocompleteChipsComponent,
    ConsultantComponent,
    TableColumnPickerComponent,
    OrderFilterComponent,
    DepartmentsComponent,
    DepartmentComponent,
    PivotViewComponent,
    PivotViewDrowdownComponent,
    PivotViewTableComponent,

    TotalPipe,
    TruncatePipe,
    CurrencyFormatPipe,
    CurrencyFormatterDirective,

    ConsultantSummaryComponent,
    LoginComponent,

    DayMonthYearFormat,
    MonthYearFormat,
    YearFormat,
    EnterNavigation,
    SettingsComponent,
    AuditComponent,
    InvoicesComponent,
    ConsultantSummaryDetailsComponent,
    NoDuplicateTaskNumberValidatorDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatMomentDateModule,
    MsalModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  exports: [
    AutocompleteChipsComponent
  ],
  providers: [
    DecimalPipe,
    CurrencyFormatPipe,
    GroupGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    { provide: LOCALE_ID, useValue: 'da-DK' },
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, strict: false } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },

  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
