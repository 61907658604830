<mat-select-autocomplete [options]="projectTypesDropdown" [formControl]="projectTypesControl" [multiple]="false" [searchbar]="false"
    label="Projekttype">
</mat-select-autocomplete>

<app-autocomplete-chips [formControl]="projectOwnerControl" label="Projektejer" [displayFn]="consultantsService.displayFn"
[valueFn]="consultantsService.valueFn" [options]="consultants"></app-autocomplete-chips>

<app-autocomplete-chips [formControl]="departmentControl" label="Forretningsområde" [displayFn]="departmentsService.displayFn"
[valueFn]="departmentsService.valueFn" [options]="departments"></app-autocomplete-chips>

<mat-form-field appearance="outline" style="height: 75px;">
    <mat-label>Søg</mat-label>
    <input matInput type="text" [formControl]="textControl">
</mat-form-field>

<button mat-raised-button color="primary" (click)="reset()" style="margin-top: 10px;">Nulstil</button>