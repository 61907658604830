import { Component, ViewChild } from '@angular/core';
import { Invoice, InvoiceStatus } from '../models/order.model';
import { InvoiceService } from '../services/invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Department } from '../models/department.model';
import { DepartmentService } from '../services/department.service';
import { InvoiceComponent } from './invoice/invoice.component';
import { Consultant } from '../models/consultant.model';
import { ConsultantService } from '../services/consultant.service';
import { FormControl } from '@angular/forms';
import { combineLatest, concat, debounceTime, distinctUntilChanged, map, merge, mergeMap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {

  loading = true;
  displayedColumns: string[] = ['actions', 'economicCustomerNumber', 'status', 'date', 'taskNumber', 'projectLead', 'department', 'customerName', 'total', 'totalIncludingExpenses'];
  @ViewChild(MatTable<Invoice>) table: MatTable<Invoice>;
  dataSource: MatTableDataSource<Invoice> = new MatTableDataSource([]);
  @ViewChild(MatSort) sorter: MatSort;
  departments: Department[];
  consultants: Consultant[];
  invoices: Invoice[];

  consultantDropdown;
  consultantControl = new FormControl();
  taskNumberControl = new FormControl();
  showManualControl = new FormControl(false);

  constructor(
    private snackBar: MatSnackBar,
    protected dialog: MatDialog,
    protected invoiceService: InvoiceService,
    protected departmentsService: DepartmentService,
    public consultantsService: ConsultantService,
    protected auth: AuthService
  ) {

  }

  async ngAfterViewInit(): Promise<void> {
    this.consultants = await this.consultantsService.list();
    this.departments = await this.departmentsService.list();

    this.consultantDropdown = [{ display: "Nulstil", value: null }, ...this.consultants.map(_ => ({ display: _.initials, value: _.id }))];

    setTimeout(() => {
      this.consultantControl.setValue(this.consultants.find(_ => _.email == this.auth.activeAccount.username)?.id);
    });

    merge(
      this.consultantControl.valueChanges,
      this.taskNumberControl.valueChanges,
      this.showManualControl.valueChanges
    ).pipe(distinctUntilChanged(), debounceTime(500),
      map(_ => ({ consultant: this.consultantControl.value, taskNumber: this.taskNumberControl.value, showManual: this.showManualControl.value }))
    ).subscribe(_ => this.filter(_.consultant, _.taskNumber, _.showManual));

    await this.loadInvoices();
  }

  filter(consultant: any, taskNumber: any, showManual: boolean) {
    let invoices = this.invoices;

    if (consultant) {
      invoices = [...invoices.filter(_ => _.projectLeadId == consultant)];
    }

    if (taskNumber) {
      invoices = [...invoices.filter(_ => _.taskNumber.toLocaleLowerCase().includes(taskNumber.toLocaleLowerCase()))];
    }

    if (!consultant && !taskNumber)
      invoices = this.invoices;

    this.dataSource = new MatTableDataSource(
      invoices.filter(_ => _.status == InvoiceStatus.Manual && showManual
        ? true : _.status != InvoiceStatus.Manual));
  }

  async loadInvoices() {
    this.loading = true;
    this.invoices = await this.invoiceService.list();
    this.dataSource = new MatTableDataSource(this.invoices);
    this.dataSource.sort = this.sorter;
    this.loading = false;
  }

  statusName(s: InvoiceStatus) {
    switch (s) {
      case InvoiceStatus.Open:
        return "Åben";
      case InvoiceStatus.SentToEconomic:
        return "Sendt";
      case InvoiceStatus.Manual:
        return "Manuel";
      default: return "Ukendt";
    }
  }

  consultant(id: string) {
    return this.consultants?.find(_ => _.id == id);
  }

  department(id: string) {
    return this.departments.find(_ => _.id == id)?.name;
  }

  openInvoice(invoice: Invoice) {
    let clone = { ...invoice };
    clone.salesRealizatorDistribution = clone.salesRealizatorDistribution.map(_ => ({ ..._ }));
    clone.distribution = clone.distribution.map(_ => ({ ..._ }));
    clone.tasks = clone.tasks.map(_ => ({ ..._ }));
    clone.expenses = clone.expenses.map(_ => ({ ..._ }));
    clone.transport = clone.transport.map(_ => ({ ..._ }));

    let dialogRef = this.dialog.open(InvoiceComponent, {
      data: { invoice: clone },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async updated => {
      if (updated) {
        // let idx = this.dataSource.data.findIndex(_ => _.id == updated.id);
        // this.dataSource.data[idx] = updated;
        // this.table.renderRows();
        await this.loadInvoices();
      }
    });
  }

  async removeInvoice(invoice: Invoice) {
    if (window.confirm(`Vil du slette faktura til ${invoice.customerName} på DKK ${this.invoiceService.totalIncludingExpenses(invoice)}`)) {
      await this.invoiceService.delete(invoice.id);
      this.loadInvoices();
      this.snackBar.open("Faktura slettet");
    }
  }
}
