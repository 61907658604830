import { ChangeDetectorRef, Component, HostListener, LOCALE_ID, OnInit, Renderer2, ViewChild } from '@angular/core';
import { OrderComponent } from './order/order.component';
import { MatDialog } from '@angular/material/dialog';
import { Invoice, InvoiceStatus, Order } from '../models/order.model';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrderService } from '../services/order.service';
import { TableColumnPickerComponent } from '../table-column-picker/table-column-picker.component';
import { ColumnOption } from '../models/column-option';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { ConsultantService } from '../services/consultant.service';
import { DepartmentService } from '../services/department.service';
import { Consultant, ExecutorAmount } from '../models/consultant.model';
import { Department } from '../models/department.model';
import { InvoiceComponent } from '../invoices/invoice/invoice.component';
import { MatSort } from '@angular/material/sort';
import { InvoiceService } from '../services/invoice.service';
import { OrderFilter, OrderFilterComponent } from './order-filter/order-filter.component';
import { AuthService } from '../services/auth.service';

const COLUMN_ORDER_KEY = "orderColumnOrder";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  // providers: [DatePipe, {provide: LOCALE_ID, useValue: 'da-DK'}]
})
export class OrdersComponent implements OnInit {

  orders: Order[] = [];
  invoices: Invoice[] = [];
  distribution = new Map<string, ExecutorAmount[]>();

  @ViewChild(OrderFilterComponent) orderFilter: OrderFilterComponent;
  @ViewChild(MatTable<Order>) table: MatTable<Order>;
  @ViewChild(MatSort) sorter: MatSort;
  dataSource: MatTableDataSource<Order> = new MatTableDataSource([]);
  displayedColumns = [
    'actions',
    'taskNumber',
    'year',
    'consultantId',
    'customerAndTask',
    'projectSumExVat',
    'pipelineProjectSumExVat',
    'pipelinePercentForSuccess',
    'pipelineWeightedProjectSum',
    'leadCreatorId',
    'salesRealizators',
    'executors',
    'expectedInvoice',
    'distribution',
    'comment',
    'departmentId',
    'hubspotDeal',
  ];
  // datePipe = new DatePipe('da-DK');

  editing: Order;
  editingProperty: string;

  previousIndex: number;

  consultants: Consultant[];
  departments: Department[];

  containerHeight = "500px";

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Update viewport dimensions on window resize event
    this.getTableHeight();
  }

  getTableHeight() {
    let subtract = 64;
    this.containerHeight = (window.innerHeight - subtract) + "px";
  }

  constructor(
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public ordersService: OrderService,
    public consultantsService: ConsultantService,
    public departmentsService: DepartmentService,
    public invoiceService: InvoiceService,
    protected auth: AuthService,
  ) {

    // if (!localStorage.getItem(COLUMN_ORDER_KEY)) {
    //   let columns = new Order();
    //   localStorage.setItem(COLUMN_ORDER_KEY, JSON.stringify(this.displayedColumns));
    // } else {
    //   this.displayedColumns = JSON.parse(localStorage.getItem(COLUMN_ORDER_KEY));
    // }

  }

  async ngOnInit(): Promise<void> {
    this.getTableHeight();
    this.consultants = await this.consultantsService.list();
    this.departments = await this.departmentsService.list();

    this.dataSource.sortingDataAccessor = (data, header) => {
      if (header == "consultantId" || header == "leadCreatorId") {
        return this.consultant(data[header])
      } else if (header == "expectedInvoice") {
        return data.expectedInvoice[0]?.month;
      }
      return data[header];
    };

    this.dataSource.sort = this.sorter;
    this.dataSource.filterPredicate = (order, filter) => {
      let f = filter?.toLocaleLowerCase();
      if (order.comment?.toLowerCase()?.includes(f)) return true;
      if (order.consultant?.initials?.toLowerCase()?.includes(f)) return true;
      if (order.customerAndTask?.toLowerCase()?.includes(f)) return true;
      if (order.department?.name?.toLowerCase()?.includes(f)) return true;
      if (order.economicCustomerNumber?.toLowerCase()?.includes(f)) return true;
      if (order.leadCreator?.initials?.toLowerCase()?.includes(f)) return true;
      if (order.salesRealizators?.some(_ => this.consultant(_.consultantId)?.toLocaleLowerCase().includes(f))) return true;
      if (order.executors?.some(_ => this.consultant(_.consultantId)?.toLocaleLowerCase().includes(f))) return true;
      if (order.taskNumber?.toLowerCase()?.includes(f)) return true;
      return false;
    };

    this.sorter.sort({ disableClear: false, id: "created", start: "desc" });

    await this.loadOrders();
  }

  private async loadOrders() {
    this.orders = (await this.ordersService.list());
    this.invoices = await this.invoiceService.list();

    this.orders?.forEach(_ => {
      this.distribution[_.id] = this.invoiced(_);
    });

    this.orderFilter.emitLast(true);
  }

  invoiced(order: Order) {
    let result: ExecutorAmount[] = [];
    let invoices = this.invoices.filter(_ => _.orderId == order.id);

    invoices?.forEach(i => {
      i.distribution?.forEach(d => {
        let c = result.find(_ => _.consultantId == d.consultantId);
        if (!c) {
          result.push(new ExecutorAmount(d.consultantId, d.orderId, d.amount));
        } else {
          c.amount += d.amount;
        }
      });
    });

    return result;
  }

  invoiceStatus(order: Order) {
    let invoice = this.invoices.find(_ => _.orderId == order.id);

    if (invoice?.status == InvoiceStatus.SentToEconomic) {
      return "green";
    }

    return "inherit";
  }

  createInvoice(order: Order) {
    let dialogRef = this.dialog.open(InvoiceComponent, {
      data: { order: order },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async createdInvoice => {
      if (createdInvoice) {
        console.log("invoice created");
        await this.loadOrders();
      }
    });
  }

  async deleteOrder(order: Order) {
    if (window.confirm("Er du sikker på at du vil slette: " + order.customerAndTask + "?"))
      if (await this.ordersService.delete(order.id)) {
        await this.loadOrders();
      } else {
        window.alert("Denne ordre kan ikke slettes");
      }
  }

  consultant(id: string) {
    return this.consultants?.find(_ => _.id == id)?.initials ?? "";
  }

  department(id: string) {
    return this.departments?.find(_ => _.id == id)?.name ?? "";
  }

  createOrder() {
    let dialogRef = this.dialog.open(OrderComponent, {
      // width: 'fit-content',
      data: { order: new Order() },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async createdOrder => {
      if (createdOrder) {
        await this.loadOrders();
      }
    });
  }

  openOrder(order: Order) {
    let clone = { ...order };
    clone.salesRealizators = clone.salesRealizators.map(_ => ({ ..._ }));
    clone.executors = clone.executors.map(_ => ({ ..._ }));

    let dialogRef = this.dialog.open(OrderComponent, {
      // width: 'fit-content',
      data: { order: clone },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async updatedOrder => {
      if (updatedOrder) {
        await this.loadOrders();
      }
    });
  }

  dropListDropped(event: CdkDragDrop<any, any>) {
    if (event) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      localStorage.setItem(COLUMN_ORDER_KEY, JSON.stringify(this.displayedColumns));
      this.table.renderRows();
    }
  }

  async exportAllToExcel() {
    let file = await this.ordersService.exportAllToExcel();
    const blob = new Blob([file.body], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;` });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `ordrebog_export.xlsx`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // shouldShowColumn(input: string): boolean {
  //   let columnPicker = new TableColumnPickerComponent;
  //   return columnPicker.formControl.value.includes(input);
  // }

  displayedColumnsChanger(event: string[]) {
    setTimeout(() => {
      this.displayedColumns = ["actions", ...event];
    });
  }

  filter(filter: OrderFilter) {
    let orders = this.orders;

    if (filter) {
      if (filter.owner?.length > 0) {
        orders = [...orders.filter(_ => filter.owner.find(__ => _.consultantId == __))];
      }

      if (filter.department?.length > 0) {
        orders = [...orders.filter(_ => filter.department.find(__ => _.departmentId == __))];
      }

      if (filter.owner?.length == 0 && filter.department?.length == 0)
        orders = this.orders;

      switch (filter.type) {
        case "ORDERS":
          orders = [...orders.filter(_ => _.projectSumExVat)];
          break;
        case "PIPELINE":
          orders = [...orders.filter(_ => _.pipelineProjectSumExVat)];
          break;
      }
    }

    this.dataSource.data = orders;
    this.dataSource.filter = filter?.text;
  }
}

