import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseHttpService } from './base-http.service';
import { Invoice } from '../models/order.model';
import { Settings } from '../models/settings.model';
import { FeeBasisAdjustment } from '../models/fee-basis-adjustment.model';

@Injectable({
  providedIn: 'root'
})
export class FeeBasisAdjustmentService extends BaseHttpService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  // public get() {
  //   return firstValueFrom(this.http.get<Settings>(`${this.baseApiUrl}/settings`));
  // }

  public save(adjustment: FeeBasisAdjustment) {
    if (!adjustment.leadCreator)
      delete adjustment.leadCreator;

    if (!adjustment.salesRealizator)
      delete adjustment.salesRealizator;

    if (!adjustment.executor)
      delete adjustment.executor;
    
    return firstValueFrom(this.http.post<FeeBasisAdjustment>(`${this.baseApiUrl}/fee-basis-adjustment/save`, adjustment));
  }
}