import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Invoice, InvoiceExpense, InvoiceStatus, InvoiceTask, Order } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { Consultant, DistributionAmount, ExecutorAmount, SalesRealizatorAmount } from 'src/app/models/consultant.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import * as moment from 'moment';
import { Department } from 'src/app/models/department.model';
import { DepartmentService } from 'src/app/services/department.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Settings } from 'src/app/models/settings.model';

export const DDMMYYYY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_FORMAT },
  ]
})
export class InvoiceComponent implements OnInit, AfterViewInit {

  loading = true;

  settings: Settings;
  order: Order;
  invoice: Invoice;

  projectLeadDropdown = [];
  projectLeadControl: FormControl;
  consultants: Consultant[];
  productsDropdown = [];
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected orders: OrderService,
    protected consultantsService: ConsultantService,
    protected departmentsService: DepartmentService,
    protected invoiceService: InvoiceService,
    protected settingsService: SettingsService,
    protected cd: ChangeDetectorRef
  ) {
    this.order = this.data.order;
    this.invoice = this.data.invoice;

  }

  async ngOnInit(): Promise<void> {

    if (!this.order && this.data?.invoice?.orderId) {
      this.order = await this.orders.get(this.invoice.orderId);
    }

    if (!this.data.invoice) {
      this.invoice = new Invoice();
      this.invoice.date = new Date();
      this.invoice.orderId = this.order.id;
      this.invoice.departmentId = this.order.departmentId;
      this.invoice.taskNumber = this.order.taskNumber;
      this.invoice.economicCustomerNumber = this.order.economicCustomerNumber;
      this.invoice.distribution = [];
    }

    this.projectLeadControl = this.fb.control(this.invoice.projectLeadId);

    if (!this.invoice.distribution || this.invoice.distribution.length == 0) {
      this.order.executors?.forEach(_ => {
        if (!this.invoice.distribution?.some(__ => __.consultantId == _.consultantId)) {
          this.invoice.distribution.push(new DistributionAmount(_.consultantId, this.order.id, null));
        }
      });
    }

    this.consultants = await this.consultantsService.list();
    this.projectLeadDropdown = this.consultants.map(_ => {
      return { display: _.initials, value: _.id };
    });
    this.projectLeadControl.valueChanges.subscribe(_ => {
      this.invoice.projectLeadId = _;
    });

    this.settings = await this.settingsService.get();
    this.productsDropdown = this.settings?.products?.map(_ => {
      return { display: _.number + ": " + _.name, value: _.number };
    });

    if (!this.invoice.id) {
      this.projectLeadControl.setValue(this.order.leadCreatorId);
    }

    if (!this.invoice.tasks || this.invoice.tasks.length == 0) {
      this.invoice.tasks = [new InvoiceTask(null, null, null, "")];
    }

    this.loading = false;
  }

  async ngAfterViewInit(): Promise<void> {

  }

  updateDistribution() {
    let executors = this.order.executors;
    this.invoice.distribution.forEach(d => {
      let share = executors.find(_ => _.consultantId == d.consultantId).amount / this.order.projectSumExVat;
      d.amount = share * this.totalTasks();
    });
  }

  consultant(id: string) {
    return this.consultants?.find(_ => _.id == id)?.initials ?? "";
  }

  amountChanged(elem, executor: ExecutorAmount) {
    let amount = this.parseNumber(elem.value);
    executor.amount = +amount;
  }

  parseNumber(value: any) {
    return value.replace(/\./g, '').replace(/,/g, '.');
  }

  close() {
    if (window.confirm("Dine ændringer vil ikke blive gemt"))
      this.dialogRef.close();
  }

  async create() {
    try {
      if (!this.ngModels.map(_ => _.valid).every(_ => _)) {
        this.projectLeadControl.markAllAsTouched();
        this.ngModels.forEach(_ => _.control.markAllAsTouched());
      } else {
        if (!this.invoice.id)
          this.invoice.salesRealizatorDistribution = this.order.salesRealizators.map(
            _ => new DistributionAmount(_.consultantId, this.order.id, (_.amount / this.order.projectSumExVat) * this.totalTasks()));
        let result = await this.invoiceService.save(this.invoice);
        this.dialogRef.close(result);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async deleteInvoice() {
    if (window.confirm("Slet denne faktura?")) {
      await this.invoiceService.delete(this.invoice.id);
      this.dialogRef.close();
    }
  }

  dateSelected(property: string, event: MatDatepickerInputEvent<Moment>, datepicker: MatDatepicker<Date>) {
    if (datepicker) {
      datepicker.close();
    }

    this.invoice[property] = event.value.add(12, "hours").toDate();
    console.log(this.invoice.date);
  }

  deleteLine(idx: number, array: any[]) {
    array.splice(idx, 1);
  }

  addTask() {
    this.invoice.tasks.push(new InvoiceTask(null, null, null));
  }

  addTransport() {
    if (!this.invoice.transport) {
      this.invoice.transport = [];
    }

    this.invoice.transport.push(new InvoiceTask(null, null, this.settings?.transportFee));
  }

  addExpense() {
    if (!this.invoice.expenses) {
      this.invoice.expenses = [];
    }

    this.invoice.expenses.push(new InvoiceExpense(null, null, null));
  }

  hasCredit() {
    return this.invoice.tasks?.length > 0 || this.invoice.transport?.length || this.invoice.expenses?.length;
  }

  total() {
    let total = this.invoice.tasks?.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0) || 0;
    total += this.invoice.transport?.map(_ => _.amount * _.price).reduce((prev, curr) => prev + curr, 0) || 0;
    total += this.invoice.expenses?.map(_ => +_.price).reduce((prev, curr) => prev + curr, 0) || 0;
    return total;
  }

  totalTasks() {
    return this.invoice.tasks?.map(_ => _.amount * _.price)?.reduce((prev, curr) => prev + curr, 0) || 0;
  }

  totalDistribution() {
    return this.invoice.distribution?.map(_ => _.amount)?.reduce((prev, curr) => prev + curr, 0) || 0;
  }

  async sendToEconomic() {
    if (window.confirm("Send faktura til economic?")) {
      try {
        if (await this.invoiceService.draft(this.invoice.id)) {
          this.dialogRef.close();
        } else {
          window.alert("Overførsel til economic fejlede");
        }
      } catch (error) {
        window.alert("Overførsel til economic fejlede: " + (error as any)?.message ?? "Ukendt fejl");
      }
    }
  }
}
