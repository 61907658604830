<mat-toolbar color="primary" [class.staging]="isStaging()">
  <img src="assets/logo-600.webp" height="40px">
  @if (isStaging()) {
  <span style="margin: 0 1rem;"></span>
  <span>STAGING</span>
  }
  @if (auth.activeAccount && (auth.isAdmin() || auth.isConsultant())) {
  <span style="margin: 0 1rem;"></span>
  <button mat-button routerLink="orders">Projekter</button>
  <button mat-button routerLink="pivot">Rapport</button>
  <button mat-button routerLink="consultant-summary">Totaler</button>
  <button mat-button routerLink="invoices">Fakturaer</button>
  <button mat-button routerLink="fee-basis">Honorargrundlag</button>
  @if (auth.isAdmin()) {
  <button mat-button routerLink="admin">Administration</button>
  }
  }

  @if(this.auth.activeAccount) {
  <span style="flex: 1 1 auto;"></span>
  {{ auth.activeAccount?.name }}
  <button mat-button (click)="logout()">Log ud</button>
  }

</mat-toolbar>

@if (!isIframe) {
<router-outlet></router-outlet>
}