@if (!loading) {
<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="leadCreator">
    <th mat-header-cell *matHeaderCellDef>Lead creator</th>
    <td mat-cell *matCellDef="let order"> {{order.leadCreator?.initials}} </td>
    <td mat-footer-cell *matFooterCellDef>Total</td>
  </ng-container>
  <ng-container matColumnDef="salesRealizators">
    <th mat-header-cell *matHeaderCellDef>Salgsrealisator</th>
    <td mat-cell *matCellDef="let order">
      @if (salesRealizator) {
        {{ consultant(salesRealizator) }}
      } @else {
        @for (c of order.salesRealizators; track c) {
          {{ consultant(c.consultantId) }} <br>
          }
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="executors">
    <th mat-header-cell *matHeaderCellDef>Udførende</th>
    <td mat-cell *matCellDef="let order">
      @if (executor) {
        {{ consultant(executor) }}
      } @else {
        @for (c of order.executors; track c) {
          {{ consultant(c.consultantId) }} <br>
          }
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="taskNumber">
    <th mat-header-cell *matHeaderCellDef>Opgave ID</th>
    <td mat-cell *matCellDef="let order"> {{order.taskNumber}} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="customerAndTask">
    <th mat-header-cell *matHeaderCellDef>Kunde og opgave</th>
    <td mat-cell *matCellDef="let order"> {{order.customerAndTask}} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
  <ng-container matColumnDef="projectSum">
    <th mat-header-cell *matHeaderCellDef>Projektsum</th>
    <td mat-cell class="right-align" *matCellDef="let order"> {{order.projectSumExVat | number: '1.0-0'}} </td>
    <td mat-footer-cell class="right-align" *matFooterCellDef>{{ dataSource | total: 'projectSumExVat' | number:
      '1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="pipelineProjectSum">
    <th mat-header-cell *matHeaderCellDef>Pipeline projektsum</th>
    <td mat-cell class="right-align" *matCellDef="let order"> {{order.pipelineProjectSumExVat | number: '1.0-0'}}
    </td>
    <td mat-footer-cell class="right-align" *matFooterCellDef>{{ dataSource | total: 'pipelineProjectSumExVat' |
      number: '1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="weightedPipelineProjectSum">
    <th mat-header-cell *matHeaderCellDef>Vægtet pipeline projektsum</th>
    <td mat-cell class="right-align" *matCellDef="let order">
      @if (order.pipelineProjectSumExVat) {
      {{order.pipelineProjectSumExVat * (order.pipelinePercentForSuccess/100) | number: '1.0-0'}}
      }
    </td>
    <td mat-footer-cell class="right-align" *matFooterCellDef>{{ dataSource | total: 'pipelineWeightedProjectSum' |
      number: '1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="sales">
    <th mat-header-cell *matHeaderCellDef>Salgsrealisator, DKK</th>
    <!-- TODO: Add in correct value for sales below -->
    <td mat-cell class="right-align" *matCellDef="let order"> {{ amount(order, "salesRealizators") | number:
      '1.0-0'}}</td>
    <td mat-footer-cell class="right-align" *matFooterCellDef>{{ dataSource | total: 'salesRealizators':salesRealizator  | number:
      '1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="executorAmount">
    <th mat-header-cell *matHeaderCellDef>Personlig omsætning</th>
    <td mat-cell class="right-align" *matCellDef="let order">{{ amount(order, "executors") | number:
      '1.0-0'}} </td>
    <td mat-footer-cell class="right-align" *matFooterCellDef>{{ dataSource | total: 'executors':executor  | number:
      '1.0-0'}}</td>
  </ng-container>

  <ng-container matColumnDef="distribution">
    <th mat-header-cell *matHeaderCellDef>Faktureret</th>
    <td mat-cell class="right-align" *matCellDef="let order">{{ amount(order, "distribution") | number:
      '1.0-0'}} </td>
    <td mat-footer-cell class="right-align" *matFooterCellDef>{{ dataSource | total: 'distribution':executor | number:
      '1.0-0'}}</td>
  </ng-container>

  <tr mat-header-row class="header-row" *matHeaderRowDef="displayedColumns;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row class="footer-row" *matFooterRowDef="displayedColumns;sticky:true"></tr>
</table>
}