import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConsultantSummaryDetails } from 'src/app/models/consultant-summary-details.model';
import { Consultant } from 'src/app/models/consultant.model';
import { Order } from 'src/app/models/order.model';
import { ConsultantService } from 'src/app/services/consultant.service';

@Component({
  selector: 'app-consultant-summary-details',
  templateUrl: './consultant-summary-details.component.html',
  styleUrls: ['./consultant-summary-details.component.scss']
})
export class ConsultantSummaryDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() consultantId: string;
  @Input() year: number;
  @Input() type: "personalRevenue" | "invoicedPersonalRevenue" | "weightedPipeline" | "leads" | "realizedSales";

  displayedColumns: string[] = ['taskNumber', 'customerAndTask', 'amount'];
  @ViewChild(MatTable<Order>) table: MatTable<Order>;
  dataSource: MatTableDataSource<Order>;

  details: ConsultantSummaryDetails;

  constructor(
    protected consultantService: ConsultantService
  ) {

  }

  async ngOnInit(): Promise<void> {
  }

  async ngAfterViewInit(): Promise<void> {
    
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.consultantId) return;

    this.details = await this.consultantService.summaryDetails(this.consultantId, this.year);

    let orders = [];

    if (this.type == "leads")
      this.details.leads.forEach(_ => {
        let amount = _.projectSumExVat;

        if (amount > 0)
          orders.push({
            order: _,
            type: "Lead",
            amount
          });
      });

    if (this.type == "weightedPipeline")
      this.details.weightedPipeline.forEach(_ => {
        let amount = _.pipelineProjectSumExVat * (_.pipelinePercentForSuccess / 100);
        if (amount > 0)
          orders.push({
            order: _,
            type: "Vægtet pipeline",
            amount
          });
      });

    if (this.type == "personalRevenue")
      this.details.personalRevenue.forEach(_ => {
        let amount = _.executors.find(_ => _.consultantId == this.consultantId)?.amount;
        if (amount > 0)
          orders.push({
            order: _,
            type: "Personlig omsætning",
            amount
          });
      });

    if (this.type == "invoicedPersonalRevenue")
      this.details.invoicedPersonalRevenue.forEach(_ => {
        let amount = _.distribution.find(_ => _.consultantId == this.consultantId)?.amount;
        if (amount > 0)
          orders.push({
            order: _.order,
            type: "Faktureret Personlig omsætning",
            amount
          });
      });

    if (this.type == "realizedSales")
      this.details.realizedSales.forEach(_ => {
        let amount = _.salesRealizators.find(_ => _.consultantId == this.consultantId)?.amount;
        if (amount > 0)
          orders.push({
            order: _,
            type: "Real. salg",
            amount
          });
      });

    this.dataSource = new MatTableDataSource<Order>(orders);
  }
}
