import { Injectable } from '@angular/core';
import { ColumnOption } from '../models/column-option';
import { Order } from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class TableColumnPickerService {

  columnOptions: ColumnOption[] = []

  orderInstance: Order = new Order;
  orderAttributeNames: string[] = [];

  displayMap = new Map<string, string>(
    [
      ['taskNumber', 'Opgave id'],
      ['year', 'År'],
      ['consultantId', 'Konsulent'],
      ['customerAndTask', 'Kunde og opgave'],
      ['projectSumExVat', 'Projektsum ex. MOMS'],
      ['pipelineProjectSumExVat', 'Pipeline projektsum ex. MOMS'],
      ['pipelinePercentForSuccess', 'Pipeline % for win'],
      ['pipelineWeightedProjectSum', 'Pipeline vægtet projektsum'],
      ['leadCreatorId', 'Lead creator'],
      ['salesRealizators', 'Salgsrealisatorer'],
      ['executors', 'Udførende'],
      ['expectedInvoice', 'Forventet faktura måned'],
      ['distribution', 'Faktureret'],
      ['comment', 'Kommentar'],
      ['departmentId', 'Forretningsområde'],
      ['economicCustomerNumber', 'Economic kunde nr.'],
    ]
  );

  constructor() {
    this.orderAttributeNames = [
      'taskNumber',
      'year',
      'consultantId',
      'customerAndTask',
      'projectSumExVat',
      'pipelineProjectSumExVat',
      'pipelinePercentForSuccess',
      'pipelineWeightedProjectSum',
      'leadCreatorId',
      'salesRealizators',
      'executors',
      'expectedInvoice',
      'distribution',
      'comment',
      'departmentId',
      'hubspotDeal',
    ];
    this.columnOptions = this.transformOrderAttributes(this.orderAttributeNames);
  }

  getOptions(): ColumnOption[] {
    return this.columnOptions
  }

  setOptions(columnState: ColumnOption[]): void {
    this.columnOptions = columnState;
  }

  transformOrderAttributes(input: string[]): ColumnOption[] {
    let result: ColumnOption[] = [];

    input.forEach((_) => {
      let columnOptionPair = { "display": this.displayMap.get(_), "value": _ };
      if (_ != "consultant" && _ != 'leadCreator' && _ != 'department')
        result.push(columnOptionPair);
    })

    return result;
  }
}
